import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128071311527'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Cest": [
      "",
      "35 1/2 / 90",
      "37 3/4 / 96",
      "40 / 102",
      "42 1/2 / 108",
      "",
      "",
      "44 3/4 / 114",
      "47 1/4 / 120"
    ],
    "Length": [
      "",
      "26 3/4 / 68",
      "27 1/2 / 70",
      "28 1/4 / 72",
      "29 / 74",
      "",
      "",
      "30 / 76",
      "30 3/4 / 78"
    ]
  }

  return (
    <Layout>
      <SEO title="Shop - Baggage Tag T-shirt Man" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify